/* .heading-text{
    color: #28245F;
text-align: center;
font-family: Montserrat;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 33px;
text-transform: capitalize;
}
.heading-desc{
    color: #D84D4D;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 33px; 
text-transform: capitalize;
} */
.content3 {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #ffffff;
  transition: 0.4s ease-in-out;
}
.home-section3 {
  margin: 2rem 0rem;
  padding: 2rem 0rem;
}
.section-content3 {
  background: #ff7301;
  display: flex;
  margin-top: 3rem;

  width: 100%;
}
.content3-heading {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 91.667% */
  text-transform: capitalize;
}
.content3:hover .content3-heading {
  background: linear-gradient(90deg, #ffae00 6%, #fd7200 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  transition: 0.3s ease-in-out;
}
.content3:hover {
  background-color: rgb(31, 31, 31);
  transition: 0.3s ease-in-out;
}

.content3-detail {
  color: #fdfdfd;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 144% */
  text-transform: capitalize;
}
.line {
  width: 395px;
  height: 1px;
  background: #ffffff;
}
.content3 {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.v-icon {
  width: fit-content;
}

@media screen and (max-width: 1100px) {
  .section-content3 {
    flex-direction: column;
  }
  .content3-heading {
    font-size: 30px;
  }
  .content3-detail {
    font-size: 20px;
  }
  .line {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .content3-heading {
    font-size: 30px;
  }
  .content3-detail {
    font-size: 20px;
  }
  .line {
    width: 300px;
  }
}

@media (max-width: 550px) {
  .content3-heading {
    font-size: 25px;
  }
  .content3-detail {
    font-size: 16px;
  }
  .line {
    width: 200px;
  }
}
