.contact-heading-section {
  margin-bottom: 2.5rem;
  margin-top: 200px;
}

.contact-heading {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  text-transform: capitalize;
}
.contact-heading-desc {
  color: #d84d4d;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  text-transform: capitalize;
}

.contact-card {
  border-radius: 21px;
  border: 1px solid #d1d1d1;
  background: #fcfcfc;
  width: 315px;
  height: 256px;
  padding: 2.5rem 2.5rem;
  margin: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-card-title,
.contact-card-detail {
  color: #d84d4d;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  text-transform: capitalize;
}

.contact-card-detail {
  text-decoration-line: underline;
}
.contact-card-title-desc {
  color: #616161;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
}
.contact-card-icon {
  width: 70px;
  height: 70px;
  border-radius: 21px;
  border: 1px solid #e4e4e4;
  background: #fff;
  backdrop-filter: blur(7.5px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1100px) {
}
@media screen and (max-width: 945px) {
  .contact-heading {
    font-size: 30px;
  }
  .contact-heading-desc {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .contact-heading {
    font-size: 25px;
  }
  .contact-heading-desc {
    font-size: 16px;
  }
}
