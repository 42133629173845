.oil-banner7 {
  margin-top: 10rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 40vh;
  flex-shrink: 0;
  border-radius: 10px;

  /* background: url("../../assets/aboutus/bg-aboutus.jpg"); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Montserrat;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay7 {
  border-radius: 10px;
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;

  z-index: 0;
}
.oil-title7 {
  z-index: 1;
}
/* .about-us-heading {
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

  text-transform: capitalize;
  background: linear-gradient(90deg, #fd00dc -25.78%, #00fff0 190.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.about-us-decs {
  width: 100%;
  color: #000;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;

  text-transform: capitalize;
  margin: 2rem 0;
}

@media screen and (max-width: 1100px) {
  .about-us-decs {
    font-size: 20px;
  }
}
@media screen and (max-width: 945px) {
  .about-us-decs {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
